import * as Uuid from "uuid";
import isNetworkError from "../lib/is-network-error";

import { IIdentifiable } from "../domain/domainModels/IIdentifiable";
import { FunctionalError } from "../domain/errors/FunctionalError";
import { IRepository } from "../domain/IRepository";
import { OfflineService } from "../domain/OfflineService";
import { Notifier } from "../domain/pubsub/Notifier";
import { bufferStatus, Topics } from "../domain/pubsub/Topics";
import { timestamp } from "../domain/Types";
import { LogService } from "../logging/LogService";
import { DependencyInjectionUtils } from "../util/DependencyInjectionUtils";
import { Locker } from "../util/Locker";
import { CachedRepository } from "./CachedRepository";

export class OfflineRepository<T extends IIdentifiable>
  extends CachedRepository<T>
  implements IRepository<T>
{
  private locker: Locker = new Locker();
  private instanceId = Uuid.v4(); // Some ID to make a distinction between counting nr of items in bufferRepo instances

  constructor(
    protected override readonly logService: LogService,
    protected override readonly cacheRepo: IRepository<T>,
    protected override readonly dataRepo: IRepository<T>,
    protected override readonly notifier: Notifier,
    protected readonly offlineService: OfflineService,
    protected readonly bufferRepo: IRepository<T>,

    protected override initializeFromCache: boolean = false,
    protected override readonly updates?: () => Promise<void>,
  ) {
    // Remove last arguments from check because it is optional
    const xArguments = [...arguments];
    DependencyInjectionUtils.validateDependenciesDefined(
      xArguments.slice(0, -1),
    );

    super(
      logService,
      cacheRepo,
      dataRepo,
      notifier,
      initializeFromCache,
      updates,
    );

    // Sync when going online
    this.notifier.subscribe(Topics.OFFLINE, async (offline: boolean) => {
      if (!offline) {
        void this.syncBuffer();
      }
    });

    // Sync any unsynced saves on app startup
    this.notifier.subscribe(Topics.SYNC_BUFFER, async () => {
      await this.syncBuffer();
    });
  }

  public override async getAll(): Promise<readonly T[]> {
    try {
      let result = await super.getAll();
      result = await this.getAllAndMergeBuffer(result);
      await this.offlineService.setOnline();
      return result;
    } catch (error) {
      if (isNetworkError(error)) {
        await this.offlineService.setOffline();
        const result = await this.cacheRepo.getAll();
        return await this.getAllAndMergeBuffer(result);
      } else {
        throw error;
      }
    }
  }

  private async getAllAndMergeBuffer(
    models: readonly T[],
  ): Promise<readonly T[]> {
    const result = models.slice();
    const bufferResult = await this.bufferRepo.getAll();
    for (const model of bufferResult) {
      const index = result.findIndex((m) => m.uuid === model.uuid);
      if (index === -1) {
        // Add new models
        result.push(model);
      } else {
        // Udate existing models
        result[index] = model;
      }
    }
    return result;
  }

  /* istanbul ignore next */ //Not used during integration
  public override async tryGetByUuid(uuid: string): Promise<T | undefined> {
    const bufferedModel = await this.bufferRepo.tryGetByUuid(uuid);
    if (bufferedModel) {
      return bufferedModel;
    } else {
      return await super.tryGetByUuid(uuid);
    }
  }

  public override async save(model: T): Promise<timestamp> {
    await this.bufferRepo.save(model);
    await this.syncBuffer();
    return this.lastDataRepoTimestamp;
  }

  private async syncBuffer() {
    try {
      await this.locker.lock();
      await this.internalSyncBuffer();
    } finally {
      this.locker.unlock();
    }
  }

  private async internalSyncBuffer() {
    const bufferedSaves = await this.bufferRepo.getAll();
    let nrOfItemsInBuffer = bufferedSaves.length;
    await this.publishNrOfItemsInBuffer(nrOfItemsInBuffer);

    if (bufferedSaves.length === 0) {
      return;
    }

    try {
      await this.notifier.publish(Topics.SYNCING, true);
      for (const model of bufferedSaves) {
        await super.save(model); // Updates lastDataRepoTimestamp
        await this.bufferRepo.delete(model.uuid);
        nrOfItemsInBuffer--;
        await this.publishNrOfItemsInBuffer(nrOfItemsInBuffer);
        await this.offlineService.setOnline();
      }
    } catch (error) {
      if (isNetworkError(error)) {
        await this.offlineService.setOffline();
        return;
      } else {
        throw error;
      }
    } finally {
      await this.notifier.publish(Topics.SYNCING, false);
    }

    await this.internalSyncBuffer(); // Try again to see it there is more data.
  }

  private async publishNrOfItemsInBuffer(nrOfItemsInBuffer: number) {
    const param: bufferStatus = {
      instanceId: this.instanceId,
      nrOfItemsInBuffer: nrOfItemsInBuffer,
    };

    // TODO: FIX type in notifier. The type is generic on the class, but we use a single instance
    // for multipkle types. Can we use a generic declared on these methods instead?
    await this.notifier.publish(Topics.ITEMS_IN_BUFFER, param);
  }

  public override async deleteAll(): Promise<timestamp> {
    if (this.offlineService.isOffline()) {
      throw new FunctionalError("Can't delete all data when offline");
    }

    try {
      await this.locker.lock();
      return await super.deleteAll();
    } finally {
      this.locker.unlock();
    }
  }

  // getTimestamp not used as getTimestamp is for caching, so never executes here

  // hard to test during integration
  /* istanbul ignore next */
  public override async delete(uuid: string): Promise<timestamp> {
    if (this.offlineService.isOffline()) {
      throw new FunctionalError("Can't delete data when offline");
    }

    try {
      await this.locker.lock();
      return await super.delete(uuid);
    } finally {
      this.locker.unlock();
    }
  }

  public override async import(models: readonly T[]): Promise<timestamp> {
    if (this.offlineService.isOffline()) {
      throw new FunctionalError("Can't import when offline");
    }
    try {
      await this.locker.lock();
      return await super.import(models);
    } finally {
      this.locker.unlock();
    }
  }
}
