import * as Bcrypt from "bcryptjs";
import * as Cheerio from "cheerio";

import { ConfigurationManager } from "brainsupporter-core/lib/config/ConfigurationManager";
import { AbstractCompositionRoot } from "brainsupporter-core/lib/domain/AbstractCompositionRoot";
import { ContextModel } from "brainsupporter-core/lib/domain/domainModels/ContextModel";
import { IEncryptedModel } from "brainsupporter-core/lib/domain/domainModels/IEncryptedModel";
import { UserModel } from "brainsupporter-core/lib/domain/domainModels/UserModel";
import { EncryptionPasswordService } from "brainsupporter-core/lib/domain/EncryptionPasswordService";
import { EncryptionService } from "brainsupporter-core/lib/domain/EncryptionService";
import { FunctionalError } from "brainsupporter-core/lib/domain/errors/FunctionalError";
import { EventStore } from "brainsupporter-core/lib/domain/EventStore";
import { DomainEventBus } from "brainsupporter-core/lib/domain/pubsub/DomainEventBus";
import { SmartCaptureWebParser } from "brainsupporter-core/lib/domain/SmartCapture/SmartCaptureWebParser";
import { EventMigrator } from "brainsupporter-core/lib/migrations/events/EventMigrator";
import { UserModelMigrator } from "brainsupporter-core/lib/migrations/user/UserModelMigrator";
import { UserConfigModelMigrator } from "brainsupporter-core/lib/migrations/userconfig/UserConfigModelMigrator";
import { ApiRepository } from "brainsupporter-core/lib/repository/ApiRepository";
import { AppendOnlyRepository } from "brainsupporter-core/lib/repository/AppendOnlyRepository";
import { EncryptedRepository } from "brainsupporter-core/lib/repository/EncryptedRepository";
import { MigratedValidatedRepository } from "brainsupporter-core/lib/repository/MigratedValidatedRepository";
import { OfflineRepository } from "brainsupporter-core/lib/repository/OfflineRepository";
import { PagedApiRepository } from "brainsupporter-core/lib/repository/PagedApiRepository";
import { ResilientRepository } from "brainsupporter-core/lib/repository/ResilientRepository";
import { SingleRepository } from "brainsupporter-core/lib/repository/SingleRepository";
import { ValidatedRepository } from "brainsupporter-core/lib/repository/ValidatedRepository";
import { InMemorySecureCache } from "brainsupporter-core/lib/util/InMemorySecureCache";
import { RestUtils } from "brainsupporter-core/lib/util/RestUtils";

import { UserConfigModel } from "brainsupporter-core/lib/domain/domainModels/UserConfigModel";
import { DomainEventModel } from "brainsupporter-core/lib/domain/events/DomainEventModel";
import { ApplicationInsightsLogService } from "./applicationInsights/ApplicationInsightsLogService";
import { BrowserWebParser } from "./BrowserWebParser";
import { IndexedDbRepository } from "./repository/IndexedDbRepository";
import { LocalStorageSecureCache } from "./util/LocalStorageSecureCache";
import { WebConfig } from "./WebConfig";

/* istanbul ignore file */ // Test use TestCompositionRoot

export class WebCompositionRoot extends AbstractCompositionRoot {
  protected static override staticallyInitialized = false;

  protected override setConfigurableDependencies() {
    this.BcryptHash = Bcrypt.hash;
    this.BcryptGenSalt = Bcrypt.genSalt;
    this.Cheerio = Cheerio.default;
    this.Open = (url: string) => {
      const win = window.open(url, "_blank");

      if (!win || win.closed || typeof win.closed == "undefined") {
        throw new FunctionalError(
          "cannot open multiple tabs because of the popup blocker in the browser. Allow popups for this page for this to work.",
        );
      }
    };
    this.FetchFn = (input: RequestInfo, init?: RequestInit) => {
      return window.fetch(input, init);
    };
    this.RestUtils = new RestUtils(this.FetchFn);

    this.ConfigurationManager = new ConfigurationManager(new WebConfig());

    this.LogService = new ApplicationInsightsLogService(
      this.ConfigurationManager,
    );

    this.EncryptionKeySecureCache = new InMemorySecureCache();
    this.EncryptionService = new EncryptionService(
      this.BcryptHash,
      this.EncryptionKeySecureCache,
    );

    const retryInterval = [10, 100, 1000, 5000];

    // TODO: Can we use OfflineRepository here?
    this.UserRepository = new SingleRepository(
      new MigratedValidatedRepository<UserModel>(
        new ResilientRepository(
          new ApiRepository<UserModel>(
            this.LogService,
            this.RestUtils,
            this.ConfigurationManager,
            "api/users",
            "UserModel",
          ),
          this.OfflineService,
          this.LogService,
          retryInterval,
        ),
        new UserModelMigrator(),
      ),
      UserModel,
    );

    this.EncryptedDomainEventRepository =
      new PagedApiRepository<IEncryptedModel>(
        this.LogService,
        this.RestUtils,
        this.ConfigurationManager,
        "api/events",
        "IEncryptedModel", // TODO: This wil not work when we ever get multiple encrypted repos. Should be something with events?
      );

    this.EncryptionPasswordService = new EncryptionPasswordService(
      this.LogService,
      this.Notifier,
      this.ConfigurationManager,
      this.EncryptionService,
      this.UserRepository,
      this.BcryptGenSalt,
      new LocalStorageSecureCache(),
      this.EncryptionKeySecureCache,
    );

    this.UserConfigRepository = new SingleRepository(
      new OfflineRepository(
        this.LogService,
        new IndexedDbRepository<UserConfigModel>("UserConfigRepository"),
        new MigratedValidatedRepository<UserConfigModel>(
          new ResilientRepository(
            new EncryptedRepository(
              new ApiRepository<IEncryptedModel>(
                this.LogService,
                this.RestUtils,
                this.ConfigurationManager,
                "api/userconfig",
                "UserConfigModel",
              ),
              this.EncryptionPasswordService,
            ),
            this.OfflineService,
            this.LogService,
            retryInterval,
          ),
          new UserConfigModelMigrator(),
        ),
        this.Notifier,
        this.OfflineService,
        new IndexedDbRepository<UserConfigModel>("UserConfigBufferRepository"),
        // async () => {}, // TODO: Do what here?
        // true,// TODO: True?
      ),
      UserConfigModel,
    );

    // Repository to facilitate en/decrypting, caching and retries
    this.DomainEventRepository = new AppendOnlyRepository(
      new OfflineRepository(
        this.LogService,
        new IndexedDbRepository<DomainEventModel>("DomainEventRepository"),
        new MigratedValidatedRepository(
          new ResilientRepository(
            new EncryptedRepository(
              this.EncryptedDomainEventRepository, // Repository with encrypted data
              this.EncryptionPasswordService,
            ),
            this.OfflineService,
            this.LogService,
            retryInterval,
          ),
          new EventMigrator(),
        ),
        this.Notifier,
        this.OfflineService,
        new IndexedDbRepository<DomainEventModel>(
          "DomainEventBufferRepository",
        ),
        true,
        () => this.reloadEvents(),
      ),
    );

    this.EventBus = new DomainEventBus();
    this.EventStore = new EventStore(this.EventBus, this.DomainEventRepository);

    this.ContextRepository = new SingleRepository(
      new ValidatedRepository( // TODO: How to deal with migration? Reset on error or use MigratedValidatedRepository
        new IndexedDbRepository<ContextModel>("ContextRepository"),
      ),
      ContextModel,
    );

    this.SmartCaptureWebParser = new SmartCaptureWebParser(
      new BrowserWebParser(
        this.RestUtils,
        this.ConfigurationManager.baseUrlApi(),
      ),
    );
  }
}
