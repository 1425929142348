import { IConfig } from "brainsupporter-core/lib/config/IConfig";

/* istanbul ignore next */ // Coverage can be improved
export class WebConfig implements IConfig {
  public readonly baseUrlApi = "api";
  public readonly localDataFolder = "data";
  public readonly storeInAzure = true;
  public readonly enableEncryption = true;
  public readonly disabledCommands = [];
}
