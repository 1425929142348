import { IWebParser } from "brainsupporter-core/lib/domain/SmartCapture/IWebParser";
import { DependencyInjectionUtils } from "brainsupporter-core/lib/util/DependencyInjectionUtils";
import { RestUtils } from "brainsupporter-core/lib/util/RestUtils";

/* istanbul ignore next */ // Coverage can be improved
export class BrowserWebParser implements IWebParser {
  constructor(
    private readonly restUtils: RestUtils,
    private readonly baseUrlApi: string,
  ) {
    DependencyInjectionUtils.validateDependenciesDefined(arguments);
  }

  public async fetchTitle(url: string): Promise<string> {
    // This has a privacy concern as we send a url to the server.
    // Make the feature configurable by later
    const fetchServerSideUrl =
      this.baseUrlApi + "/fetchtitle?url=" + encodeURIComponent(url);
    const response = await this.restUtils.get(fetchServerSideUrl);
    return response.text();
  }
}
